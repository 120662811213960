// @ts-ignore
import supportTermPDF from '@/static/assets/akuity-support-terms-2024-july.pdf';

const SupportTerm = () => {
  if (typeof window !== 'undefined') {
    window.location.href = supportTermPDF;
  }

  return <></>;
};

export default SupportTerm;
